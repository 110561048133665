<template>
<div>
  <b-container fluid>
    <b-row class="mb-4" v-if="!permissions.readPanel">
      <b-col>
        Access only for RRIC Admins
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-show="loading">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.readPanel">
      <b-col>
        <div>
          <b-card no-body>
            <b-tabs pills card vertical>
              <b-tab title="Start" active>
                <b-card-text>
                  <h1>Admin panel</h1>
                      <b-form-checkbox
                        id="checkbox-show-auto-tag-badge"
                        v-model="showAutoTagsBadge"
                        :value="true"
                        :unchecked-value="false"
                      >
                        show auto tags badge
                      </b-form-checkbox>
                      <div class="mt-4">
                        <b-nav vertical class="w-25">
                          <b-nav-item v-if="permissions.accounts"><b-link :to="{ name: 'Accounts' }">Accounts</b-link></b-nav-item>
                          <b-nav-item><b-link :to="{ name: 'AutotagsStats' }">Autotags stats</b-link></b-nav-item>
                        </b-nav>
                        </div>
                </b-card-text>
              </b-tab>
              <b-tab title="News sources" lazy><b-card-text><news-sources :permissionDelete="permissions.deleteSource" :permissionSave="permissions.saveNews" /></b-card-text></b-tab>
              <b-tab title="Recent activity" lazy><b-card-text><recent-activity /></b-card-text></b-tab>
              <b-tab title="News stats" lazy><b-card-text><news-count /></b-card-text></b-tab>
              <b-tab title="News stats matrix" lazy><b-card-text><news-matrix /></b-card-text></b-tab>
              <b-tab title="Tags" lazy><b-card-text><tags :permissionDelete="permissions.deleteTag"/></b-card-text></b-tab>
              <b-tab title="Categories" lazy><b-card-text><categories :permissionDelete="permissions.deleteCategory"/></b-card-text></b-tab>
              <b-tab title="News any tag" lazy><b-card-text><news-any-tag /></b-card-text></b-tab>
              <b-tab title="News publisher" lazy><b-card-text><news-publisher /></b-card-text></b-tab>
              <b-tab v-if="permissions.adminNotifications" title="Notifications" lazy><b-card-text><notifications-admin /></b-card-text></b-tab>
              <b-tab title="Deleted topics" lazy><b-card-text><deleted-topics :permissionRecover="permissions.recoverTopic" /></b-card-text></b-tab>
              <b-tab title="Links Q" lazy><b-card-text><links /></b-card-text></b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'

import Categories from '@/components/Categories.vue'
import DeletedTopics from '@/components/DeletedTopics.vue'
import Links from '@/components/Links.vue'
import NewsAnyTag from '@/components/NewsAnyTag.vue'
import NewsPublisher from '@/components/NewsPublisher.vue'
import NewsSources from '@/components/NewsSources.vue'
import NewsCount from '@/components/NewsCount.vue'
import NewsMatrix from '@/components/NewsMatrix.vue'
import NotificationsAdmin from '@/components/NotificationsAdmin.vue'
import RecentActivity from '@/components/RecentActivity.vue'
import Tags from '@/components/Tags.vue'

export default {
  components: {
    Categories,
    DeletedTopics,
    Links,
    NewsAnyTag,
    NewsCount,
    NewsMatrix,
    NewsPublisher,
    NewsSources,
    NotificationsAdmin,
    RecentActivity,
    Tags
  },
  computed: {
    showAutoTagsBadge: {
      get () {
        return this.$store.state.showAutoTagsBadge
      },
      set (payload) {
        this.$store.commit('setShowAutoTagsBadge', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'RRIC admin', action: 'open RRIC admin' })
    this.permissions.accounts = ac.can(this.user.acgroups).readAny('account').granted
    this.permissions.adminNotifications = ac.can(this.user.acgroups).readAny('adminNotifications').granted
    this.permissions.readPanel = ac.can(this.user.acgroups).readAny('rricpanel').granted
    this.permissions.deleteSource = ac.can(this.user.acgroups).deleteAny('source').granted
    this.permissions.deleteCategory = ac.can(this.user.acgroups).deleteAny('regcategory').granted
    this.permissions.deleteTag = ac.can(this.user.acgroups).deleteAny('tag').granted
    this.permissions.recoverTopic = ac.can(this.user.acgroups).deleteAny('topic').granted
    this.permissions.saveNews = ac.can(this.user.acgroups).createAny('publication').granted
    if (this.permissions.readPanel) {
      this.getSources()
    } else {
      this.$router.push({ name: 'Start' })
    }
  },
  data () {
    return {
      loading: true,
      permissions: {
        accounts: false,
        adminNotifications: false,
        deleteCategory: false,
        deleteSource: false,
        readPanel: false,
        deleteTag: false,
        recoverTopic: false,
        saveNews: false
      },
      sources: []
    }
  },
  methods: {
    getSources: async function () {
      this.$logger.debug('getSources started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/sources'
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        this.sources = _.sortBy(response, 'source')
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    }
  }
}
</script>

<style>
</style>
