<template>
  <b-container>
    <b-row class="mb-4">
      <b-col >
        <b-card class="mb-3">
          <h3>Notifications</h3>
          <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
          <span v-if="!loading">
        <div>
          Number of notifications: {{ this.notifications.length }}
        </div>
        <div>
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search" />
              <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
          </b-input-group>
        </div>
        <b-table id="regtable"
          striped
          hover
          :items="notifications"
          :fields="fields"
          :filter="filter"
          @row-clicked="showNotification"></b-table>
          </span>
        </b-card>

    <b-modal ref="modal" size="xl" ok-only title="Notification" v-if="!loading">
      <notification-item
        :model="'notification'"
        :notification="notification"
        :tags="tags"
        :topics="topics"
      />
    </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import NotificationItem from '@/components/NotificationItem.vue'

export default {
  components: {
    NotificationItem
  },
  created: async function () {
    let response = await this.$Amplify.API.get('cosmos', '/notifications/bootstrap')
    this.tags = response.tags
    this.publishers = response.sources
    this.topics = response.topics
    this.load()
  },
  data () {
    return {
      active: false,
      fields: [
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'subject',
          sortable: true
        },
        {
          key: 'frequency',
          sortable: true
        }
      ],
      filter: '',
      loading: true,
      notification: {},
      notifications: [],
      publishers: [],
      tags: [],
      topics: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.notifications = await this.$Amplify.API.get('cosmos', `/notifications`)
        this.loading = false
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
    },
    showNotification: async function (notification, index, event) {
      this.notification = notification
      this.active = true
      this.$refs['modal'].show()
    }
  }
}
</script>
