<template>
  <div>
    <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
    <div v-if="!loading">

      <div><strong>Set date range</strong></div>
      <b-form inline class="mb-4">
        <label class="mr-2" for="dateInput">Start date:</label>
        <b-form-input id="dateInput" class="mr-2" type="date" v-model="startDate" required> </b-form-input>
        <label class="mr-2" for="dateInput">End date:</label>
        <b-form-input id="dateInput" class="mr-2" type="date" v-model="endDate" required> </b-form-input>
        <b-button variant="primary" v-on:click="load">Apply</b-button>
      </b-form>

      <div class="mb-4"><b-button variant="outline-primary" v-on:click="getXls">download</b-button></div>

      <div>
        <b-table id="table" striped hover :items="news" :fields="fields" :filter="filter" ></b-table>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import zipcelx from 'zipcelx'

export default {
  props: ['permissionDelete'],
  computed: {
    validate: function () {
      return this.tag.name
    }
  },
  created: function () {
    this.load()
  },
  data () {
    return {
      fields: [
        { key: 'date', sortable: true },
        { key: 'publisher', sortable: true },
        { key: 'name', sortable: true },
        { key: 'regtopicsName', sortable: true },
        { key: 'eventsName', sortable: true },
        { key: 'tagsName', sortable: true },
        { key: 'risksName', sortable: true }
      ],
      loading: true,
      news: [],
      startDate: moment().subtract(14, 'days').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD')
    }
  },
  methods: {
    getXls: function () {
      let data = []
      const headings = [
        { value: 'Date', type: 'string' },
        { value: 'Publisher', type: 'string' },
        { value: 'Country', type: 'string' },
        { value: 'Title', type: 'string' },
        { value: 'Body', type: 'string' },
        { value: 'Link', type: 'string' },
        { value: 'Topics', type: 'string' },
        { value: 'Tags', type: 'string' },
        { value: 'Risks', type: 'string' },
        { value: 'Events', type: 'string' },
        { value: 'Sectors', type: 'string' },
        { value: 'Categories', type: 'string' },
        { value: 'Regulatory stages', type: 'string' },
        { value: 'Tracking status', type: 'string' }
      ]
      data.push(headings)
      for (let i = 0, len = this.news.length; i < len; i++) {
        let row = [
          { value: this.news[i].date, type: 'string' },
          { value: this.news[i].publisher, type: 'string' },
          { value: this.news[i].countryName, type: 'string' },
          { value: this.news[i].name, type: 'string' },
          { value: this.news[i].body, type: 'string' },
          { value: this.news[i].link, type: 'string' },
          { value: this.news[i].regtopicsName, type: 'string' },
          { value: this.news[i].tagsName, type: 'string' },
          { value: this.news[i].risksName, type: 'string' },
          { value: this.news[i].eventsName, type: 'string' },
          { value: this.news[i].sectors, type: 'string' },
          { value: this.news[i].categories, type: 'string' },
          { value: this.news[i].regulatoryStageName, type: 'string' },
          { value: this.news[i].trackingStatusName, type: 'string' }
        ]
        data.push(row)
      }
      const config = { filename: 'reg-topic-events', sheet: { data: data } }
      zipcelx(config)
    },
    load: async function () {
      this.$logger.debug('loading tags started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/news/anytag/betweendates/${this.startDate}/${this.endDate}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        this.news = response
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    }
  }
}
</script>

<style></style>
