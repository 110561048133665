<template>
  <div>
    <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
    <div v-if="!loading">
        <div>
                <b-form-select v-model="field" :options="options"></b-form-select>
                <b-button class="mb-4 mr-2" variant="outline-primary" @click="showTable">show</b-button>
        </div>
      <div>
        <b-table id="table" striped hover :items="sources" :fields="fields"></b-table>
      </div>
    </div>
    <div><strong>Total: {{ total }}</strong></div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  created: function () {
    this.getSources()
  },
  data () {
    return {
      field: 'year',
      fields: [
        { key: 'year', sortable: true },
        { key: 'count', sortable: true }
      ],
      loading: true,
      options: [
        { value: 'year', text: 'by year' },
        { value: 'publisher', text: 'by publisher' },
        { value: 'source', text: 'by source' }
      ],
      sources: [],
      total: 0
    }
  },
  methods: {
    getSources: async function () {
      this.$logger.debug('getSources started')
      this.loading = true
      this.fields[0].key = this.field
      try {
        let apiName = 'cosmos'
        let path = `/news/count/${this.field}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        this.sources = response
        this.total = _.reduce(this.sources, function (sum, item) {
          return sum + Number(item.count)
        }, 0)
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    showTable: function () {
      this.loading = true
      this.getSources()
    }
  }
}
</script>

<style></style>
