<template>
  <div>
    <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>{{message}}</div>
    <div v-if="!loading">
      <div v-if="show.table">
        <div>
          <b-btn class="mb-2 mr-2" variant="outline-primary" @click="getXls">Download</b-btn>
          <b-form-group horizontal class="mt-4">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
          </b-form-group>
        </div>
        <div>
          Sources count: {{ sources.length }}
        </div>
        <b-table id="table" striped hover :items="sources" :fields="fields" :filter="filter">
          <template #cell()="data">
            <b-link class="mr-4" :to="{ name: 'Source', params: {id: data.item.id}}">{{data.value}}</b-link>
          </template>
        </b-table>
      </div>
      <div v-if="show.source">
        <h1>{{ source.source }}</h1>
        <div>publisher: {{ source.publisher }}</div>
        <div>source: {{ source.type }}</div>
        <div>country: {{ source.countryName }}</div>
        <div>country code: {{ source.countryCode }}</div>
        <div>language: {{ source.langName }}</div>
        <div>language code: {{ source.langCode }}</div>
        <div>
          link: <a :href="source.url" target="_blank">{{ source.url }}</a>
        </div>
        <div>parser: {{ source.parser }}</div>
        <div class="my-4">
          <b-btn class="mr-2" variant="outline-primary" @click="showLogs">show logs</b-btn>
          <b-btn class="mr-2" variant="outline-primary" @click="testFeed">test feed</b-btn>
          <b-btn class="mr-2" variant="outline-primary" @click="showTable">back to table</b-btn>
          <b-button v-if="permissionDelete"  v-b-toggle.collapse-1 variant="outline-danger">delete</b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <b-card>
                <b-btn variant="outline-danger" @click="deleteSource">confirm delete</b-btn>
            </b-card>
          </b-collapse>
        </div>
        <div class="my-4" v-if="show.logs">
            <b-card class="mb-3" v-for="log in logs" :key="'logid-' + log.id">
              <div v-if="log.error" class="text-white bg-danger"><strong>ERROR</strong></div>
              <div v-else ><strong>success</strong></div>
              <div>{{log.message}}</div>
              <div>{{log.created_at}}</div>
            </b-card>
        </div>
        <div class="my-4" v-if="show.news">
          <div v-if="news.length==0">
            <b-alert show variant="danger">Error - feed is not working</b-alert>
          </div>
            <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
              <h4 class="card-title">
                <a :href="item.link" target="_blank">{{item.name}}</a>
                <b-btn v-if="permissionSave" class="mr-2" variant="outline-primary" @click="save(item)">save</b-btn>
              </h4>
              <div class="mb-2">{{item.source}} - {{item.dateTime}}</div>
              <div class="mb-2">{{item.body}}</div>
              <div class="mb-2">{{item.publisher}} - {{item.type}}</div>
              <div class="mb-2">{{item.year}} - {{item.quarter}} - {{item.month}} - {{item.week}} </div>
              <div class="mb-2">{{item.link}}</div>
              <div class="mb-2">{{item.dateTime}}</div>
              <div class="mb-2">{{item.countryName}} - {{item.countryCode}} - {{item.langName}} - {{item.langCode}}</div>
            </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import zipcelx from 'zipcelx'

export default {
  props: ['permissionDelete', 'permissionSave'],
  created: function () {
    this.getSources()
  },
  data () {
    return {
      feedTestDate: null,
      fields: [
        { key: 'id', sortable: true },
        { key: 'source', sortable: true },
        { key: 'publisher', sortable: true },
        { key: 'source', sortable: true },
        { key: 'countryName', sortable: true },
        { key: 'maxdate', sortable: true },
        { key: 'lastSuccess', sortable: true },
        { key: 'lastError', sortable: true },
        { key: 'parser', sortable: true }
      ],
      filter: '',
      loading: true,
      logs: [],
      message: '',
      news: [],
      show: {
        logs: false,
        news: false,
        source: false,
        table: true
      },
      source: {},
      sources: []
    }
  },
  methods: {
    clearShow: function () {
      _.forIn(this.show, (value, key) => {
        this.show[key] = false
      })
    },
    deleteSource: async function () {
      this.$logger.debug('started delete')
      try {
        let apiName = 'cosmos'
        let path = `/source/${this.source.id}`
        let response = await this.$Amplify.API.del(apiName, path)
        this.$logger.debug('response', response)
        this.loading = true
        this.clearShow()
        this.show.table = true
        this.getSources()
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    getSources: async function () {
      this.$logger.debug('getSources started')
      this.loading = true
      let sources = []
      try {
        let apiName = 'cosmos'
        let path = '/sources/adminview'
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        sources = _.sortBy(response, 'source')
        this.sources = sources
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    getXls: function () {
      this.$stat.log({ page: 'RRICAdmin', action: 'download sources' })
      let data = []
      const headings = [
        { value: 'id', type: 'string' },
        { value: 'source', type: 'string' },
        { value: 'publisher', type: 'string' },
        { value: 'countryName', type: 'string' },
        { value: 'maxdate', type: 'string' },
        { value: 'lastSuccess', type: 'string' },
        { value: 'lastError', type: 'string' }
      ]
      data.push(headings)
      for (let i = 0, len = this.sources.length; i < len; i++) {
        let row = [
          { value: this.sources[i].id, type: 'string' },
          { value: this.sources[i].source, type: 'string' },
          { value: this.sources[i].publisher, type: 'string' },
          { value: this.sources[i].countryName, type: 'string' },
          { value: this.sources[i].maxdate, type: 'string' },
          { value: this.sources[i].lastSuccess, type: 'string' },
          { value: this.sources[i].lastError, type: 'string' }
        ]
        data.push(row)
      }
      const config = { filename: 'sources', sheet: { data: data } }
      zipcelx(config)
    },
    save: async function (item) {
      try {
        let apiName = 'cosmos'
        let path = `/news/publication`
        let params = { body: item }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.$bvModal.msgBoxOk('News item saved!')
      } catch (e) {
        this.$bvModal.msgBoxOk('Saving failed!')
        this.$logger.warn('event saving error: ' + e)
      }
    },
    showLogs: async function (source, index, event) {
      try {
        let apiName = 'cosmos'
        let path = `/source/${this.source.id}/scrapelog`
        let response = await this.$Amplify.API.get(apiName, path)
        this.logs = response
        this.show.logs = true
        console.log(this.logs)
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    showSource: async function (source, index, event) {
      this.source = source
      this.clearShow()
      this.show.source = true
    },
    showTable: function (source, index, event) {
      this.clearShow()
      this.show.table = true
    },
    testFeed: async function () {
      this.$logger.debug('testFeed started')
      try {
        let apiName = 'cosmos'
        let path = '/rss/test'
        let params = { body: { source: this.source } }
        let response = await this.$Amplify.API.post(apiName, path, params)
        this.news = response
        this.show.news = true
      } catch (e) {
        this.$logger.warn('testRSS error' + e)
      }
    }
  }
}
</script>

<style></style>
