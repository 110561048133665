<template>
  <div>
    <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
    <div v-if="!loading">
      <div>
        <table>
          <tr v-for="(row, rowIndex) in table" :key="'row'+rowIndex">
            <td v-for="(column, columnIndex) in row" :key="'col'+columnIndex+'-'+rowIndex">
              {{column}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  created: function () {
    this.load()
  },
  data () {
    return {
      loading: true,
      sources: [],
      table: []
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('getSources started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/news/count/year/publisher`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        this.sources = response
        const years = [2018, 2019, 2020, 2021, 2022]
        this.table.push(['publishers', ...years])
        const publishers = _.sortBy(_.uniq(this.sources.map(x => x.publisher)))
        _.each(publishers, publisher => {
          const row = [publisher]
          _.each(years, year => {
            const record = _.find(response, { 'year': year, 'publisher': publisher })
            if (record) {
              row.push(record.count)
            } else {
              row.push(0)
            }
          })
          this.table.push(row)
        })
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    }
  }
}
</script>

<style></style>
