<template>
  <div class="border-top">
    <div>
      <delete-standard :id="link.id" model="link"></delete-standard>
      saved: {{ link.saved }} | att: {{ link.attempts }} | {{ link.publisher}} | {{ link. origin }} | {{ moment(link.created_at).format('YYYY-MM-DD HH:mm') }})
      | {{ moment(link.updated_at).format('YYYY-MM-DD HH:mm') }} | {{ link.parser }} | {{ link.id }}
    </div>
    <div><a :href="link.url" target="_blank">{{ link.url }}</a></div>
  </div>
</template>

<script>
import moment from 'moment'
import DeleteStandard from '@/components/DeleteStandard.vue'

export default {
  name: 'LinkItem',
  components: {
    DeleteStandard
  },
  props: ['link'],
  methods: {
    moment: function (value) {
      return moment(value)
    }
  }
}
</script>
